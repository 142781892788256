import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { RequestService } from './request.service';
import { LayoutUtilsService } from './utils/layout-utils.service';
import { environment } from 'src/environments/environment';
import { Messaging, deleteToken, getToken, onMessage } from '@angular/fire/messaging';
import { Firestore, collection, addDoc, doc, getDoc, getFirestore, setDoc, updateDoc } from '@angular/fire/firestore';
import { Storage, getDownloadURL, ref as storagRef, uploadBytesResumable } from '@angular/fire/storage';
import { Database, ref, set, get, onValue, remove, update, child } from '@angular/fire/database';
import { MatDialog } from '@angular/material/dialog';
import { LoaderService } from './loader.service';
import { NotificationService } from './notification.service';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class FirebaseHandlersService {

  currentMessage = new BehaviorSubject(null);
  token: string = '';
  progressRef: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  firebaseRealDB: Database = undefined;

  private audioExtensions = ['mp3', 'wav', 'ogg', 'flac', 'aac'];
  private videoExtensions = ['mp4', 'avi', 'mkv', 'mov', 'wmv', 'flv'];
  private isRegistered: boolean = false;

  constructor(private requestService: RequestService, private translate: TranslateService, private layoutUtilsService: LayoutUtilsService, private messaging: Messaging, private firestore: Firestore, private storage: Storage, private realDB: Database, private dialog: MatDialog, private loader: LoaderService, private notificationService: NotificationService, private adAuthService: MsalService,) {
    this.firebaseRealDB = realDB;

    onMessage(this.messaging, (payload) => {
      // if (payload.data?.userId != this.requestService.currentUser?._id) {
      this.currentMessage.next(payload);
      this.notificationService.showNotificationUnRead.next(true);
      console.log(payload)
      // }
    });

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event: any) => {
        // console.log('event fb', event)
        // if (event.data?.data?.type == 'call') {
        //   let data = event.data.data;
        // }
      });
    }
  }

  initialize() {
    let auth = getAuth();
    signInWithEmailAndPassword(
      auth,
      environment.firestore_config.user,
      environment.firestore_config.password
    )
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log('userCredential', user);
        // this.fbAuthUser = user;
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('error', error);
        // ..
      });
  }

  registerDevice() {
    if ('serviceWorker' in navigator && window.location.pathname.indexOf('/case/map') == -1 && window.location.pathname.indexOf('/annotate') == -1 && window.location.pathname.indexOf('/location') == -1 && Notification.permission === 'granted' && !this.token) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then((registration) => {
          // this.swRegistration = registration;
          getToken(this.messaging,
            { vapidKey: environment.firebaseVapidKey, serviceWorkerRegistration: registration }).then(
              (currentToken) => {
                this.token = currentToken;
                this.tokenSubject.next(this.token);
                // console.log(currentToken)
              }).catch(e => {
                console.log(e)
                // setTimeout(() => this.registerDevice(currentUserId), 5000);
              });
          console.log('Registration successful, scope is:', registration.scope);
        }).catch((err) => {
          console.log('Service worker registration failed, error:', err);
        });
    }
  }

  sendNotificationToDevice(receiverId: string, payload: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.requestService.postRequest('notify', 'firebase/' + receiverId + '/send', {
        payload: payload
      }, (data, error) => {
        if (data)
          resolve(data);
        if (error)
          reject(error);
      });
    });
  }

  sendNotificationToType(type: string, payload: any) {
    this.requestService.postRequest('notify', 'firebase/' + type, payload, (data, error) => {

    });
  }

  subscribeTopic(topic: string, callback: any): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.token && topic)
        this.requestService.postRequest('notify', 'subscribe/topic', {
          deviceToken: this.token,
          topic: topic
        }, (data, error) => {
          if (data)
            resolve(data);
          else if (error)
            reject(error);

          if (callback)
            callback();
        });
    });
  }

  unsubscribeTopic(topic: string, callback: any): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.token && topic)
        this.requestService.postRequest('notify', 'unsubscribe/topic', {
          deviceToken: this.token,
          topic: topic
        }, (data, error) => {
          if (data)
            resolve(data);
          else if (error)
            reject(error);

          if (callback)
            callback();
        });
    });
  }

  unregisterDevice(userId: string, deviceId: string, callback: any, notValidToken: boolean = false): Promise<void> {
    this.isRegistered = false;
    return new Promise((resolve, reject) => {
      // getToken(this.messaging, { vapidKey: environment.firebaseVapidKey, serviceWorkerRegistration: this.swRegistration }).then((currentToken) => {
      deleteToken(this.messaging).then(() => {
        if (this.token && !notValidToken)
          this.requestService.postRequest('notify', 'device/delete', {
            userId: userId,
            deviceId: deviceId,
            type: "Web"
          }, (data, error) => {
            if (data)
              resolve(data);
            else if (error)
              reject(error);

            if (callback)
              callback();
          });
        else {
          resolve(undefined);

          if (callback)
            callback();
        }
      }).catch((err) => {
        console.log(err);
        if (callback)
          callback();

        reject(err);
      });
      // });
    });
  }

  unsubscribeTopics(itemName: string): Promise<void> {
    return new Promise((resolve, reject) => {
      let existingTopics = JSON.parse(localStorage.getItem(itemName)) || [];
      if (existingTopics?.length) {
        existingTopics.forEach(async topic => {
          await this.unsubscribeTopic(topic, undefined).then(() => {
            let arr = existingTopics;
            arr = arr.filter(i => i != topic);
            if (arr.length == 0)
              localStorage.removeItem(itemName);
            else
              localStorage.setItem(itemName, JSON.stringify(arr));
          });
        });
        // localStorage.removeItem(itemName);
        resolve();
      }
      else
        resolve();
    });
  }

  async logout(notValidToken: boolean = false) {
    this.loader.display(true);
    this.adAuthService.logoutPopup().subscribe(() => {
      if (this.token) {
        this.isRegistered = false;
        // let unregisterDevicePromise = this.unregisterDevice(this.requestService._currentUser._id, this.token, undefined, notValidToken);

        // await Promise.all([unregisterDevicePromise]).then(() => {
        this.requestService.logOutApi();
        // }).catch(() => {
        //   this.requestService.logOutApi();
        // });
      }
      else
        this.requestService.logOutApi();
    });
  }

  async sendMessageNotification(receiverId: string, senderId: string, body: string, title: string, type: any, name: string, actionId: string, receiverName: string, senderName: string, receiverToken, isGroup, caseId) {
    // try {
    //   // console.log('send notification');
    //   // const docRef = doc(fs, environment.firestore_config.notificationDb + '/fcm_token/users/' + receiverId);
    //   // let receiverToken = (await (await getDoc(docRef)).data());

    //   if (isGroup) {
    //     this.requestService.postRequest('notify', 'send', {
    //       topic: `chat-group-${receiverId}`,
    //       payload: {
    //         notification: {
    //           body: title + ': ' + body,
    //           title: title,
    //           click_action: '/case/' + caseId,
    //           icon: 'assets/images/logo-small.png',
    //         },
    //         data: {
    //           actionType: type,
    //           actionId: actionId.toString(),
    //           senderName: senderName,
    //           receiverName: receiverName,
    //           senderId: senderId,
    //           title: title,
    //           body: body,
    //           caseId: caseId,
    //         }
    //       }
    //     }, (data, error) => {

    //     });
    //   }
    //   //send notifications to user
    //   else if (receiverToken) {
    //     // if (!receiverToken.web_device_tokens)
    //     //   receiverToken.web_device_tokens = [];
    //     // if (!receiverToken.android_device_tokens)
    //     //   receiverToken.android_device_tokens = [];
    //     // if (!receiverToken.ios_device_tokens)
    //     //   receiverToken.ios_device_tokens = [];
    //     // let TokensArray = receiverToken.web_device_tokens.concat(receiverToken.ios_device_tokens, receiverToken.android_device_tokens);

    //     // let notificationData = {
    //     //   "message": body,
    //     //   "title": title,
    //     //   "deviceToken": receiverToken,
    //     //   "data": {
    //     //     actionType: type,
    //     //     actionId: actionId.toString(),
    //     //     senderName: senderName,
    //     //     receiverName: receiverName,
    //     //     title: title,
    //     //     body: body
    //     //   }
    //     // }

    //     this.requestService.postRequest('notify', 'firebase', {
    //       deviceToken: receiverToken,
    //       message: body,
    //       title: 'CIPHER',
    //       data: {
    //         actionType: type,
    //         actionId: actionId.toString(),
    //         senderName: senderName,
    //         receiverName: receiverName,
    //         title: title,
    //         body: body
    //       }
    //     }, (data, error) => {

    //     });
    //     // let callback = function () { }
    //     // notificationData.deviceToken = receiverToken.ios_device_tokens.concat(receiverToken.web_device_tokens);
    //     // this.requestService.sendWebFirebaseNotification(notificationData, callback);

    //     // notificationData.deviceToken = receiverToken.android_device_tokens;
    //     // this.requestService.sendAnroidFirebaseNotification(notificationData, callback);
    //   }

    //   let col: any = collection(this.firestore, environment.firestore_config.notificationDb + '/messages/user_messages/private/' + receiverId)
    //   addDoc(col, {
    //     createdAt: new Date(),
    //     title,
    //     body,
    //     type: type.toString(),
    //     viewed: false,
    //     actionType: type.toString(),
    //     actionId: actionId.toString()
    //   });
    // } catch (err) {
    //   console.log(err);
    //   throw new Error(err.message)
    // }
  }

  uploadBytesResumable(senderId, receiverId, imageId, file, type, actionId, receiverName, senderName, token, channel, isGroup, caseId): Promise<void> {
    return new Promise((resolve, reject) => {
      if (actionId == null)
        actionId = '';
      const storageRef = storagRef(this.storage, `${channel}/${imageId}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      const collectionRef = collection(this.firestore, environment.firestore_config.channelsDB + "/" + channel + "/thread");
      uploadTask.on('state_changed',
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log('Upload is ' + progress + '% done');
          this.progressRef.next(progress);
          switch (snapshot.state) {
            case 'paused':
              // console.log('Upload is paused');
              break;
            case 'running':
              // console.log('Upload is running');
              break;
          }
        },
        (error) => {
          console.log(error);
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;

            // ...

            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
          reject();
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          this.progressRef.next(0);
          // getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // console.log('downloadURL', downloadURL)
            let docData = {}
            if (type == 1) //Image
            {
              docData = {
                senderId: senderId,
                senderName: senderName,
                url: downloadURL,
                created: new Date(),
                isSeen: false,
                isDelivered: false,
                actionType: type.toString(),
                actionId: actionId.toString(),
                fileType: 'image',
              }
            } else if (type == 2) { //File
              docData = {
                senderId: senderId,
                senderName: senderName,
                fileurl: downloadURL,
                created: new Date(),
                fileName: file.name,
                isSeen: false,
                isDelivered: false,
                actionType: type.toString(),
                actionId: actionId.toString(),
                fileType: this.getFileType(file.name),
              }
            }
            addDoc(collectionRef, docData);
            if (token) {
              this.sendMessageNotification(receiverId, senderId, this.translate.instant('New message'), this.translate.instant('New message from') + ' ' + senderName, 'chat', 'chat', senderId, receiverName, senderName, token, isGroup, caseId)
            }
          });
          resolve();
        });
    });
  }

  private getFileType(fileName: string): string {
    const extension = fileName.split('.').pop()?.toLowerCase();
    if (!extension) {
      return 'unknown';
    }

    if (this.audioExtensions.includes(extension)) {
      return 'audio';
    } else if (this.videoExtensions.includes(extension)) {
      return 'video';
    } else {
      return 'file';
    }
  }

  registerToken(token: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (!this.isRegistered && this.requestService.currentUser?._id && token) {
        this.isRegistered = true;
        this.requestService.currentUser.deviceToken = token;
        localStorage.setItem('currentUser', JSON.stringify(this.requestService.currentUser));
        this.requestService.postRequest('notify', 'register', {
          userId: this.requestService.currentUser._id,
          type: "Web",
          deviceId: token
        }, (data, error) => {
          resolve();
        });
      }
      else {
        resolve();
      }
    });
  }

  async removeUserToken() {
    if (this.requestService.currentUser) {
      let currentUserId = this.requestService.currentUser._id;

      let fs = getFirestore();
      const docRef = doc(
        fs,
        environment.firestore_config.notificationDb +
        '/fcm_token/users/' +
        currentUserId
      );
      let userData = await (await getDoc(docRef)).data();

      if (userData) {
        let TokensArray = userData.web_device_tokens;
        if (!TokensArray) TokensArray = [];
        let IsThere = TokensArray.find(
          (str: string) => str === this.requestService.currentUser.deviceToken
        );

        if (IsThere != undefined) {
          TokensArray = TokensArray?.filter(
            (tok: string) => tok != this.requestService.currentUser.deviceToken
          );
          const docRef = doc(
            fs,
            environment.firestore_config.notificationDb +
            '/fcm_token/users/' +
            currentUserId
          );
          updateDoc(docRef, {
            web_device_tokens: TokensArray,
          })
            .then(() => {
              this.logout();
            })
            .catch((err) => {
              throw new Error(err.message || err);
            });
          // }
        } else {
          this.logout();
        }
      }
    }
  }

  public fsAddDoc(refrencePath: any, document: any) {
    addDoc(refrencePath, document);
  }
}